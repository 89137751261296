// exports.shouldUpdateScroll = () => {
//   return true
// }

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook
//   const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     window.scrollTo(0, 0)
//   }

//   return true
// }

exports.onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
}
